import isClient from './isClient';

export const withFetchInterceptor = () => {
  if (isClient()) {
    const { fetch: nativeFetch } = window;

    window.fetch = async (...args) => {
      const [resource, options] = args ?? [];

      if (`${resource}`.startsWith('https://fr-actions.trackonomics.net')) {
        return new Response('{}');
      }

      const response = await nativeFetch(resource as RequestInfo | URL, options);

      return response;
    };
  }
};

export default withFetchInterceptor;
