import { cn } from 'lib/utils';

export function getLabStylingClasses(
  stylingClasses: Record<string, { desktop?: string | null; mobile?: string | null }>
) {
  return Object.values(stylingClasses).reduce(
    (acc, curr) => cn(acc, curr?.mobile, curr?.desktop && `sm:${curr?.desktop}`),
    ''
  );
}
