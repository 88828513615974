/* THIS FILE IS GENERATED. DO NOT EDIT */

import type { DynamicStandaloneExtras, PropsFromComponent } from '@/types/component';
import { mergeProps } from '@/utils/merge';
import { SvenskdamStandaloneEdge as ResolvedStandaloneEdge } from 'sites/svenskdam/components/standalone/Edge';

export const Edge = ResolvedStandaloneEdge;

export type EdgeProps = PropsFromComponent<typeof Edge>;

export const EdgeWith = (extras: DynamicStandaloneExtras): typeof Edge => {
    return function Edge(props) {
        const theme = extras.theme.useTheme(extras.theme.key || 'Edge');
        return <ResolvedStandaloneEdge {...mergeProps({ options: { theme } }, props)} />;
    }
}