import { ClassNameProp } from '@/types/component';
import logger from 'lib/utils/logger';
import { isString, isUndefined } from 'typesafe-utils';
import cn from './cn';
import { isDev } from '.';

const mergeStyles = (a?: Record<string, any>, b?: Record<string, any>) => {
  return Object.keys(b ?? {}).reduce(
    (acc, key) => {
      const x = a?.[key];
      const y = b?.[key];

      if (isString(x) && isString(y)) {
        acc[key] = cn(x, y);
      } else {
        acc[key] = y;
      }

      return acc;
    },
    { ...a }
  );
};

export const getPropStylesFactory =
  (c: (...params: any) => string, styles?: Record<string, any>, name?: string) =>
  <T extends ClassNameProp, K extends T>(
    propName: string,
    propValue?: T,
    defaultValue?: K,
    theme?: Record<string, any>
  ) => {
    if (propValue === 'none') {
      return null;
    }
    const { extend: extendThemeStyles, ...themeStyles } = theme ?? {};

    const isLegacy = isUndefined(styles) || isUndefined(name);
    const isThemed = !isUndefined(theme);

    const mergedStyles = mergeStyles({ ...styles, ...(themeStyles ?? {}) }, extendThemeStyles);

    const classNameKey = `${propName}_${propValue}`;
    const className = isLegacy || !isThemed ? c`${classNameKey}` : mergedStyles[classNameKey];

    if (isString(className) && className !== classNameKey) {
      return className;
    }

    if (propValue && !isLegacy && mergedStyles?.[classNameKey] === undefined && isDev) {
      logger.warn(`Styles not found for key ${classNameKey} in "${name}"`);
    }

    const defaultClassNameKey = `${propName}_${defaultValue || 'default'}`;
    const defaultClassName = isLegacy || !isThemed ? c`${defaultClassNameKey}` : mergedStyles[defaultClassNameKey];

    if (isString(defaultClassName) && defaultClassName !== defaultClassNameKey) {
      return defaultClassName;
    }

    if (!isLegacy && mergedStyles?.[defaultClassNameKey] === undefined && isDev) {
      logger.warn(`Default styles not found for key "${defaultClassNameKey} in "${name}"`);
    }

    return null;
  };

export default getPropStylesFactory;
