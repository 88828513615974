import { getDomain } from '.';
import logger from './logger';

type UrlProp = string | URL | false | null | undefined;

export const getUrl = (url?: UrlProp, base?: UrlProp): URL | null => {
  try {
    const domain = base || getDomain();
    // @ts-expect-error
    return new URL(url, domain);
  } catch (error) {
    logger.error(`Invalid URL`, { url, base });
    return null;
  }
};

export default getUrl;
