import logger from '@/utils/logger';
import { isEqual } from 'lodash-es';
import React, { ReducerStateWithoutAction, ReducerWithoutAction } from 'react';
import { merge, mergeOptions, mergeProps } from '../utils/merge';

interface UseStableOptions {
  merge?: typeof merge;
  compare?: (a: any, b: any) => boolean;
  debug?: boolean;
}

export const useStableWith = <A, B, C, D>(options: UseStableOptions, a?: A, b?: B, c?: C, d?: D) => {
  const fn = options.merge ?? merge;
  const value = fn(a, b, c, d);
  const [result, setResult] = React.useState(value);
  const shouldUpdate = !(options.compare ?? isEqual)(result, value);

  // TODO: implement more sophisticated comparison
  // that will handle nested functions and objects
  if (shouldUpdate) {
    setResult(value);
  }

  if (options.debug) {
    logger.debug({
      value,
      result,
      shouldUpdate,
    });
  }

  return result;
};

export const useStable = <A, B, C, D>(a?: A, b?: B, c?: C, d?: D) => {
  return useStableWith({ merge: mergeOptions }, a, b, c, d);
};

export const useStableProps = <A, B, C, D>(a?: A, b?: B, c?: C, d?: D) => {
  return useStableWith({ merge: mergeProps }, a, b, c, d);
};

export const useStableOptions = <A, B, C, D>(a?: A, b?: B, c?: C, d?: D) => {
  return useStableWith({ merge: mergeOptions }, a, b, c, d);
};

export const useStableReducer: typeof React.useReducer = <R extends ReducerWithoutAction<any>>(
  reducer: R,
  initializerArg: ReducerStateWithoutAction<R>
) => {
  const instance = React.useReducer(reducer, initializerArg);
  const [value, dispatch] = instance;
  const stable = useStable(value);

  const result = React.useMemo(() => [stable, dispatch], [stable, dispatch]);

  return result as typeof instance;
};
