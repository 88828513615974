import { isArray, isEqual, isPlainObject, mergeWith, omit } from 'lodash-es';
import { twJoin } from 'tailwind-merge';

export type MergeCustomizer = (a: any, b: any, key?: string, object?: object, source?: object) => any;

type Merge = <A, B, C, D>(a?: A, b?: B, c?: C, d?: D) => A & B & C & D;

export const withCustomizers =
  (...customizers: MergeCustomizer[]): MergeCustomizer =>
  (a, b, key, object, source) => {
    for (const customizer of customizers) {
      const value = customizer(a, b, key, object, source);
      if (value !== undefined) {
        return value;
      }
    }
  };

export const classNameCustomizer: MergeCustomizer = (a, b, key) => {
  if (key === 'className' || key === 'class') {
    return twJoin(a, b);
  }
};

export const arrayCustomizer: MergeCustomizer = (a, b) => {
  if (isArray(b)) {
    return b;
  }
};

export const stableCustomizer: MergeCustomizer = (a, b) => {
  if (isEqual(a, b)) {
    return a;
  }

  if (isPlainObject(a) && isPlainObject(b)) {
    const result: Record<string, unknown> = { ...a };

    for (const key in b) {
      const A = a[key as keyof typeof a];
      const B = b[key as keyof typeof b];
      result[key] = Object.hasOwn(a, key) ? stableCustomizer(A, B) : B;
    }

    return result;
  }

  return b;
};

export const merge: Merge = <A, B, C, D, E>(a?: A, b?: B, c?: C, d?: D) => {
  const value = mergeWith({}, a, b, c, d, () => {});
  return (value ?? {}) as A & B & C & D & E;
};

export const mergeStable: Merge = <A, B, C, D, E>(a?: A, b?: B, c?: C, d?: D) => {
  const value = mergeWith({}, a, b, c, d, withCustomizers(stableCustomizer));
  return (value ?? {}) as A & B & C & D & E;
};

export const mergeOptions: Merge = <A, B, C, D, E>(a?: A, b?: B, c?: C, d?: D) => {
  const value = mergeWith({}, a, b, c, d, withCustomizers(classNameCustomizer, arrayCustomizer));
  return (value ?? {}) as A & B & C & D & E;
};

export const mergeProps: Merge = <A, B, C, D, E>(a?: A, b?: B, c?: C, d?: D) => {
  const keys = ['animation', 'colors', 'size', 'variant', 'theme'];
  const value = mergeWith({}, a, b, c, d, withCustomizers(classNameCustomizer, arrayCustomizer));
  return omit(value ?? {}, keys) as A & B & C & D & E;
};
