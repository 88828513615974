import isClient from '@/utils/isClient';
import { useEffect, useMemo, useState } from 'react';

export interface Cookies {
  [key: string]: string | undefined;
  _ga?: string;
}

export const useCookies = () => {
  const [cookiesString, setCookiesString] = useState(isClient() ? document.cookie : '');

  const handleCookieChanged: EventListener = (event) => {
    setCookiesString((event as CustomEvent).detail);
  };

  useEffect(() => {
    document.addEventListener('cookieChanged', handleCookieChanged);

    return () => {
      document.removeEventListener('cookieChanged', handleCookieChanged);
    };
  }, []);

  const cookies = useMemo(() => {
    const cookiesList = cookiesString.split(';');

    return cookiesList.reduce((acc, cookie) => {
      const [key, value] = cookie.split('=');

      if (!key) return acc;

      return {
        ...acc,
        [key?.trim()]: value?.trim(),
      };
    }, {} as Cookies);
  }, [cookiesString]);

  return useMemo(() => [cookies], [cookies]);
};

export default useCookies;
