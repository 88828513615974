import { useStable } from '@/hooks/useStable';
import { Device } from '@/types/app';
import { Component } from '@/types/component';
import { MobileAppEnvironment, MobileAppTrackingStatus } from '@/types/mobileApp';
import { GrowthBookProvider } from '@growthbook/growthbook-react';
import { AppState, AppStateProvider } from 'lib/contexts/AppState';
import { ComponentsStateProvider } from 'lib/contexts/ComponentsState';
import { MobileAppState, MobileAppStateProvider } from 'lib/contexts/MobileAppState';
import getGrowthBook from 'lib/growth-book/getGrowthBook';
import { handleOnFeatureUsage, handleTrackingCallback } from 'lib/growth-book/handleGrowthBookCallback';
import { initGrowthBook } from 'lib/growth-book/initGrowthBook';
import { parseIdFromGaCookie } from 'lib/growth-book/utils';
import useCookies from 'lib/hooks/useCookies';
import logger from 'lib/utils/logger';
import { useRouter } from 'next/router';
import { useEffect } from 'react';

const growthbook = getGrowthBook({
  enableDevMode: true,
  subscribeToChanges: true,
  trackingCallback: handleTrackingCallback,
  onFeatureUsage: handleOnFeatureUsage,
});

export interface StandaloneStateProvidersProps {
  device: Device;
  pageData: any;
  appState?: Partial<AppState>;
  mobileAppState?: Partial<MobileAppState>;
}

export const StandaloneStateProviders: Component<StandaloneStateProvidersProps> = ({ children, ...pageProps }) => {
  const { query } = useRouter();

  const { device, pageData, appState, mobileAppState } = useStable(pageProps);

  const { type, data, meta } = pageData ?? {};
  const { id, isNativeAd, accessLevel, pageType: metaPageType } = meta ?? {};
  const { publishedUrl, category, tags } = data ?? {};

  const baseAppState: AppState = useStable({
    device,
    type,
    category,
    publishedUrl,
    tags,
    pageId: id,
    isNativeAd,
    accessLevel,
    metaPageType,
  });

  const baseMobileAppState: MobileAppState = useStable({
    isMobileApp: Boolean(query.allerapp),
    appVersion: query['app-version'] as string,
    buildVersion: query['build-version'] as string,
    environment: query.environment as MobileAppEnvironment,
    trackingStatus: query['tracking-status'] as MobileAppTrackingStatus,
  });

  const [{ _ga }] = useCookies();

  useEffect(() => {
    if (!_ga) return;

    const id = parseIdFromGaCookie(String(_ga));

    if (id) {
      growthbook?.setAttributes({ id });
      initGrowthBook(growthbook);
    } else {
      logger.warn('GrowthBook @ failed to init: missing id from parseIdFromGaCookie with params ', _ga);
    }
  }, [_ga]);

  return (
    <GrowthBookProvider growthbook={growthbook!}>
      <AppStateProvider {...baseAppState} {...appState}>
        <MobileAppStateProvider {...baseMobileAppState} {...mobileAppState}>
          <ComponentsStateProvider>{children}</ComponentsStateProvider>
        </MobileAppStateProvider>
      </AppStateProvider>
    </GrowthBookProvider>
  );
};
