import { mergeDefined } from 'lib/utils';
import { createContext, useContext } from 'react';

export const ComponentContextFactory = <T extends {}>(defaultValue?: T) => {
  const Context = createContext<T>(defaultValue ?? ({} as T));
  const Provider = Context.Provider;
  const useComponentContext = (override?: Partial<T>) => mergeDefined(useContext(Context), override);

  return { Context, Provider, useComponentContext };
};
