import { GrowthBook } from '@growthbook/growthbook-react';

export const initGrowthBook = async (growthBook?: GrowthBook<any>) => {
  if (!growthBook) {
    return;
  }

  try {
    await growthBook.loadFeatures({ timeout: 5000 });
  } catch {
    // ignore
  }
};
