import { prose, typography } from 'modules/tailwind/theme.config';
import { extendTailwindMerge, fromTheme, twJoin } from 'tailwind-merge';
import { createTV } from 'tailwind-variants';

type ExtraClassGroups = 'prose' | 'typography' | 'wings';
type ExtraThemeGroups = never;

const isCommonProse = (classPart: string) => {
  return /(coverbox|factbox)/.test(classPart);
};

const isCommonTypograph = (classPart: string) => {
  return /(headline|body|preamble|blockquote|input|quote)-?\d?(xs|sm|md|lg|xl)?/.test(classPart);
};

const twMergeConfig = {
  extend: {
    classGroups: {
      prose: [{ prose: [isCommonProse, ...prose] }],
      typography: [{ text: [isCommonTypograph, ...typography] }],
      wings: [{ wings: [fromTheme('colors')] }],
    },
  },
};

export const twTheme = createTV({ twMergeConfig });

export const twMerge = extendTailwindMerge<ExtraClassGroups, ExtraThemeGroups>(twMergeConfig);

export const tw = {
  theme: twTheme,
  merge: twMerge,
  join: twJoin,
};
