import watchOnce from 'lib/utils/propsWatcher/watchOnce';

export async function tryGetWindowProp<ReturnValue>(prop: keyof Window): Promise<ReturnValue>;

export async function tryGetWindowProp<ReturnValue, FallbackValue>(
  prop: keyof Window,
  options: { fallbackValue: FallbackValue }
): Promise<ReturnValue | FallbackValue>;

export async function tryGetWindowProp<ReturnValue, FallbackValue>(
  prop: keyof Window,
  options?: { fallbackValue?: FallbackValue }
): Promise<ReturnValue | FallbackValue> {
  return watchOnce<typeof window, ReturnValue, FallbackValue>(window, prop, options);
}
